import React from 'react';

class NotFoundPage extends React.Component {

  render() {
    return (
      <div>
        No exite esta página :(
      </div>
    );
  }
}

export default NotFoundPage;