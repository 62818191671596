import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';

import './styles/index.css';
import { LocaleProvider, message } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import 'moment/locale/es';
import { auth, listenAuthState } from './firebase';
import { startLogout, startSetLoginState } from './actions/authActions';
import { translateMessage } from './helpers/translateMessage';


const store = configureStore(/* provide initial state if any */ );

const target = document.querySelector( '#root' );

listenAuthState( authUser => {
  console.log( 'authUser', authUser );
  if( authUser ) {
    auth.currentUser.getIdTokenResult()
      .then( ( idTokenResult ) => {
        if( !!idTokenResult.claims.isCommerce ) {
          // window.location.reload();
          store.dispatch( startSetLoginState( authUser.uid ) );
          renderApp();
        } else {
          message.error( translateMessage( 'PERMISSION_DENIED' ) );
          store.dispatch( startLogout() );
          renderApp();
        }
      } )
      .catch( error => console.log( 'error', error ) );
  } else {
    store.dispatch( startSetLoginState( null ) );
    renderApp();
  }
} );

const renderApp = () => render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <LocaleProvider locale={ esES }>
        <App />
      </LocaleProvider>
    </ConnectedRouter>
  </Provider>,
  target
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
