/**
 * Created by chalosalvador on 17/01/2019.
 */

export const LOGIN = '/ingreso';
// export const HOME = '/';
export const ABOUT = '/acerca-de';
export const COUPONS = '/';
export const COUPONSHISTORY = '/historial-cupones';


