import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import NotFoundPage from '../containers/NotFoundPage';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Loadable from 'react-loadable';
import Loading from '../components/Loading';
import {
    LOGIN, COUPONS, ABOUT, COUPONSHISTORY
} from '../constants/routes';

const AsyncLogin = Loadable({
    loader: () => import( '../containers/LoginPage' ),
    loading: Loading
});


// const AsyncHome = Loadable( {
//   loader: () => import( '../containers/HomePage' ),
//   loading: Loading
// } );
//
//
const AsyncAbout = Loadable({
    loader: () => import( '../containers/AboutPage' ),
    loading: Loading
});

const AsyncCoupons = Loadable({
    loader: () => import('../containers/CouponsPage'),
    loading: Loading
});

const AsyncCouponsHistory = Loadable({
    loader: () => import('../containers/CouponsHistoryPage'),
    loading: Loading
});


class AppRouter extends Component {

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props.collapsed === nextProps.collapse; // avoids re-rendering children when menu is toggled
    }

    render() {
        return (
            <Switch>
                <PublicRoute path={LOGIN} component={AsyncLogin}/>

                {/*<PrivateRoute exact={ true } path={ HOME } component={ AsyncHome } />*/}
                <PrivateRoute path={ABOUT} component={AsyncAbout}/>
                <PrivateRoute exact={true} path={COUPONS} component={AsyncCoupons}/>
                <PrivateRoute exact={true} path={COUPONSHISTORY} component={AsyncCouponsHistory}/>


                <Route component={NotFoundPage}/>
            </Switch>
        );
    }
}

export default AppRouter;
